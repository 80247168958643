<div class="card card-w-title top-level-card">
    <h1>Applications</h1>
    <container-dataview 
        [containers]="containers" 
        rows="50" 
        filterBy="data.name" 
        [sortField]="sortField" 
        [sortOrder]="sortOrder"
        (statusSelectionChange)="onStatusSelectionChange($event)"
        [isLoading]="isLoading">
        <button label="Create" class="p-button-success panel-button" pButton type="button" [routerLink]="['/applications', 'create']" icon="pi pi-plus-square" style="float:right;height:100%;" data-element-id="app-settings-components-settings-applications-create"></button>
    </container-dataview>
</div>
