<div class="card card-w-title top-level-card">
    <h1>Edit Application{{!isLoading && container ? ': ' + container.data['name'] : ''}}</h1>

    <page-header title="Edit Application{{!isLoading && container ? ': ' + container.data['name'] : ''}}" [showSpinner]="isLoading" [error]="error?.statusText"></page-header>

    <!--<p-growl [(value)]="requiredMsgs"></p-growl> -->
    <p-messages [(value)]="requiredMsgs"></p-messages>
    <p-messages [(value)]="msgs"></p-messages>

    <div class="ui-g card-content" *ngIf="container && !isLoading">
        <application-form #form [container]="container" (formSubmit)="onSubmit($event)" (formFailed)="onFailure($event)" (formCancelled)="onBack($event)"></application-form>

        <hr>

        <div class="ui-g-12">
            <container-paginated-datarow type="distribution" itemsPerPage=20 [parentContainer]="container" status="" title="Distributions" filterBy="data.name" (onReorder)="onReorder($event)">
                <button pButton type="button" label="Create" icon="pi pi-plus" [routerLink]="['/distributions', 'create', container?.id]" style="float:right;margin-left:4px;"></button>
            </container-paginated-datarow>
        </div>

        <div class="ui-g-12">
            <policy-table [parentContainer]="container">
                <button pButton type="button" label="Add" [routerLink]="['/containers', container?.id, 'relate', 'policy']" icon="pi pi-plus" style="float:right;height:100%;"></button>
            </policy-table>
        </div>

        <div class="ui-g-12">
            <origin-table [containers]="origins" [parentContainer]="container">
                <button pButton type="button" label="Add" [routerLink]="['/containers', container?.id, 'relate', 'origin']" icon="pi pi-plus" style="float:right;height:100%;"></button>
            </origin-table>
        </div>

        <div class="ui-g-12">
            <container-paginated-datarow type="user_profile" itemsPerPage=20 [parentContainer]="container" status="" title="User Profiles" filterBy="data.name" (onReorder)="onReorder($event)">
                <button pButton type="button" label="Create" icon="pi pi-plus" [routerLink]="['/user/profiles', 'create', container.id]" style="float:right;margin-left:4px;"></button>
            </container-paginated-datarow>
        </div>

        <hr>

        <div class="ui-g-12">
          <object-advancedview [object]="container" title="Advanced Editing"></object-advancedview>
        </div>
    </div>
</div>
