import { Component, OnInit, Input } from '@angular/core';
import { Container, Resource, EntityType } from '../../models';
import { SpinnerComponent } from '../../../spinner/spinner.component';
import { PageHeaderComponent } from '../../../ui/page-header.component';
import { MenuItem } from 'primeng/api';

@Component({
    selector: 'resource-datarow',
    templateUrl: 'resource-datarow.component.html',
    styleUrls: ['resource-datarow.component.css']
})
export class ResourceDatarowComponent implements OnInit {
    @Input() set resources(resources: Array<Resource>) {
        console.log(resources);
        this.allResources = resources;

        this.shownResources = [];
        resources.forEach(element => {
            this.shownResources.push(element);
            if (element.type.name !== 'file') {
                element['show'] = true;
            } else {
                this.fileResources.push(element);
                element['show'] = false;
            }
        });

        this.filter();
    }
    @Input() parentContainer: Container;
    @Input() title: string;
    @Input() rows: number;
    @Input() filterBy: string;
    @Input() sortOptions: Array<any>;
    @Input() isLoading: boolean;

    private error: string;
    private allResources: Array<Resource> = [];
    public shownResources: Array<Resource> = [];
    public fileResources: Array<Resource> = [];
    private sortField: string='created_date';
    private sortOrder: number= 1;
    private activeFilter: string;
    private filterByFields: Array<any>;
    public displayManageDialog: boolean = false;
    private rowGroupMetadata: any;
    public collapsed = false;

    public selectedOrderingOption: any;
    public orderingOptions = [
      { label: 'Created Date', value: "created_date" },
      { label: 'Created Date Desc', value: "!created_date" },
      { label: 'Published Date', value: "published_date" },
      { label: 'Published Date Desc', value: "!published_date" },
      { label: 'Updated Date', value: "updated_date" },
      { label: 'Updated Date Desc', value: "!updated_date" },
      { label: 'Expiration Date', value: "expiration_date" },
      { label: 'Expiration Date Desc', value: "!expiration_date" },
    ]

    constructor() {}

    ngOnInit() {
        let filters = this.filterBy.split(', ');
        this.filterByFields = [];
        filters.forEach(element => {
            this.filterByFields.push(element.split('.'));
        });
        this.filterByFields.push('file');
    }

    onFilter(value) {
        this.activeFilter = value;
        this.filter();
    }

    showManageDialog() {
        this.displayManageDialog = true;
    }

    closeManageDialog() {
        this.displayManageDialog = false;
    }

    filter() {
        this.shownResources.forEach(resource => {
            if (this.activeFilter && this.activeFilter !== '') {
                resource['show'] = false;
                this.filterByFields.forEach(filter => {
                    if (!resource['show']) {
                        let result = resource;
                        if (typeof(filter) === 'object') {
                            filter.forEach(element => {
                                result = result[element];
                            });
                        }
                        if (String(result).toLowerCase().includes(this.activeFilter.toLowerCase())) {
                            resource['show'] = true;
                        }
                    }
                });
            } else {
                resource['show'] = true;
            }

            if (resource.type.name === 'file') {
                resource['show'] = false;
            }
        });
        this.sort()
        this.findDuplicateTags()
    }

    onSortChange() {
        let value = this.selectedOrderingOption
        if (value.indexOf('!') === 0) {
            this.sortOrder = -1;
            this.sortField = value.substring(1, value.length);
        } else {
            this.sortOrder = 1;
            this.sortField = value;
        }
        this.sort();
    }

    sort() {
        if (!this.sortField || this.sortField === '') {
            this.shownResources = [];
            this.allResources.forEach(element => {
                this.shownResources.push(element);
            });
            this.filter();
            return;
        }

        this.shownResources.sort((a, b) => {
            let value1 = this.resolveObjectField(a, this.sortField);
            let value2 = this.resolveObjectField(b, this.sortField);

            // Convert to Date objects if sorting by 'date'
            if (!isNaN(Date.parse(value1)) && !isNaN(Date.parse(value2)) ) {
              value1 = new Date(value1)
              value2 = new Date(value2)
            }

            let result = null;
            if (value1 == null && value2 != null) {
                result = -1;
            } else if (value1 != null && value2 == null) {
                result = 1;
            } else if (value1 == null && value2 == null) {
                result = 0;
            } else if (typeof value1 === 'string' && typeof value2 === 'string') {
                result = value1.localeCompare(value2);
            } else {
                result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
            }
            return (this.sortOrder * result);
        });
    }

    resolveObjectField(object: Object, value: string) {
        let result;
        let tokens = value.split('.');
        tokens.forEach(element => {
            result = object[element];
        });
        return result;
    }

    resourceRemoved(e) {
        let i = this.allResources.indexOf(e.resource);
        this.allResources.splice(i, 1);

        this.shownResources = [];
        this.allResources.forEach(element => {
            this.shownResources.push(element);
        });

        this.filter();
        this.sort();
    }

    findDuplicateTags() {
      const tagMap = new Map();

      // First pass: Populate tagMap with tags associated with each type.name
      this.shownResources.forEach(resource => {
        const typeName = resource.type.name;
        resource.tags.forEach(tag => {
          const key = `${typeName}-${tag.value}`;
          if (!tagMap[key]) {
              tagMap[key] = [];
          }
          tagMap[key].push(resource);
        });
      });

      // Second pass: Identify duplicates and build list of duplicate_tags for a resource
      this.shownResources.forEach(resource => {
        const typeName = resource.type.name;
        resource['duplicate_tag'] = new Set();
        resource.tags.forEach(tag => {
            const key = `${typeName}-${tag.value}`;
            if (tagMap[key].length > 1) {
                resource['duplicate_tag'].add(tag.value);
            }
        });
      });
    }

}
