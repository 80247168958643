import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { HttpResponse } from '@angular/common/http';
import { PageHeaderComponent } from '../../../ui/page-header.component';
import { ContainerService } from '../../../content/services/container/container.service';
import { Container } from '../../../content/models/container';
import { Tag } from '../../../content/models/tag';
import { Status } from '../../../content/models/status';
import { SpinnerComponent } from '../../../spinner/spinner.component';
import { Message } from 'primeng/api';
import { JsonEditorComponent, JsonEditorOptions } from 'ang-jsoneditor';
import { PersonFormComponent } from '../../forms/person-form/person-form.component';
import { MessageService } from 'primeng/api';
import { handleError } from '../../../shared/utilities';

@Component({
    selector: 'cast-edit',
    providers: [
        ContainerService
        //JobsService
    ],
    templateUrl: 'cast-edit.component.html',
    styleUrls: ['cast-edit.component.css']
})
export class CastEditComponent implements OnInit, OnDestroy {
    @ViewChild('form') form: PersonFormComponent;
    private sub: any;
    public container: Container;
    public error: HttpResponse<any>;
    public isLoading = false;
    public msgs: Message[] = [];
    public requiredMsgs: Message[] = [];
    private validSections: Object = {};

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private location: Location,
        public containerService: ContainerService,
        private msgSrv: MessageService
    ) {}

    ngOnInit() {
        console.log('person edit initialized');

        this.sub = this.route.params.subscribe(params => {
            let id = +params['id']; // + converts string to number
            console.log('id', id);
            this.isLoading = true;
            this.containerService
                .get(id)
                .subscribe(
                    res => {
                        this.container = res;
                        this.initValues();
                        this.validateFormData();
                        console.log("person", this.container);
                    },
                    err => this.error = err,
                    () => this.isLoading = false
                );
        });
    }

    refetchContainer() {
        this.isLoading = true;
        this.containerService
            .get(this.container.id)
            .subscribe(
                res => {
                    this.container = res;
                    this.initValues();
                    this.validateFormData();
                },
                err => this.error = err,
                () => this.isLoading = false
            );

    }

    ngOnDestroy() {
        this.sub.unsubscribe();
    }

    isDateValid(dateString: string) {
        var date_regex = /^[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/;
        if (date_regex.test(dateString)) {
            return true;
        }
        return false;
    }

    validateFormData() {
        this.msgs = [];
        let valid = true;
        for (let field of ['Query Filters', 'Filter Options', 'Transform Options', 'Render Options']) {
            if (this.validSections[field.toLowerCase()] === false) {
                valid = false;
                this.msgs.push({
                    severity: 'error',
                    summary: `${field}: Invalid JSON`,
                    detail: 'Cannot save invalid JSON.'
                });
            }
        }
        return valid;
    }

    onSubmit(e) {
        console.log('submit', e.model);
        let valid = this.validateFormData();
        if (!valid) {
            return;
        }
        this.requiredMsgs = [];
        this.containerService
            .save(this.container)
            .subscribe(
                res => {
                    console.log(res);
                    this.requiredMsgs = [];
                    this.requiredMsgs.push({ severity: 'success', summary: 'Changes Saved', detail: '' });
                    this.container = Object.assign(new Container(), this.container);
                },
                err => {
                    console.log(err);
                    this.requiredMsgs = [];
                    let errorMessages = handleError(err);
                    errorMessages.forEach(errorMessage => {
                      if (err.status && err.status == 403) {
                          this.msgSrv.add({ key: 'chMsg', severity: 'error', summary: 'Error', detail: errorMessage });
                      } else {
                          this.requiredMsgs.push({ severity: 'error', summary: 'Error', detail: errorMessage });
                      }
                    })
                },
                () => this.isLoading = false
            );
    }

    onBack(e) {
        if (!this.form || this.form.isPristine() || confirm('You may have unsaved changes that will not be saved if you leave this page. Are you sure you want to leave this page?')) {
            this.location.back();
        }
    }

    onFailure(e) {
        this.requiredMsgs = [];
        this.requiredMsgs.push({ severity: 'error', summary: 'Changes Were Not Saved', detail: 'There are errors in the form, please review and fix errors before saving.' });
    }

    private initValues() {
    }

    private changeJson(field: string, editor: JsonEditorComponent) {
        try {
            var editorData = editor.get();
        } catch (e) {
            // Keep current value
            this.validSections[field] = false;
            return;
        }
        this.validSections[field] = true;
        this.container.data[field] = editorData;
    }

}
