import { Component, OnInit, OnDestroy} from '@angular/core';
import { AppComponent } from './app.component';
import { AuthService } from '../app/auth/auth.service';
import { ContainerService } from './content/services/container/container.service';
import { AppContext } from './app.context';
import { Router} from '@angular/router';
import { Location } from '@angular/common';
import { MenuItem } from 'primeng/api';



@Component({
    selector: 'app-sidebar',
    providers: [
        ContainerService,
    ],
    templateUrl: './app.sidebar.component.html',
    styleUrls: ['./app.sidebar.component.css'],
})
export class AppSideBarComponent implements OnInit, OnDestroy {
    contentMenu: MenuItem[];
    createMenu: any[];
    createSettingsMenu: any[];
    uploadMenu: any[];
    metricsMenu: any[];
    metricsReportsMenu: any[];
    testingReportsMenu: any[];
    configMenu: any[];
    newItemMenu: MenuItem[];
    workflowMenu: any[];
    salesMenu: any[];
    adsMenu: any[];
    tagMenu: any[];
    schemasMenu: any[];
    createCommerceMenu:any[];
    displayDetailedUploadWindow: boolean = false;
    displayQuickUploadWindow: boolean = false;

    contentTabItems: MenuItem[];
    commerceTabItems: MenuItem[];
    metricsTabItems: MenuItem[];
    testingTabItems: MenuItem[];
    configTabItems: MenuItem[];
    metricsSubMenu: MenuItem[];
    testingSubMenu: MenuItem[];
    configTagMenu: MenuItem[];

    isLoadingCurate = true;
    activeOrigin: string = '';

    private distributions = [];
    private error: string;
    private userPermSub: any;
    private activeOriginSub: any;
    private userPermissions: object;

    constructor(public app: AppComponent,
                private containerService: ContainerService,
                private appContext: AppContext,
                private location: Location,
                private router: Router,
                private authService: AuthService) {}

    showQuickUploadDialog() {
        this.displayQuickUploadWindow = true;
    }

    closeQuickUploadDialog() {
        this.displayQuickUploadWindow = false;
    }

    showDetailedUploadDialog() {
        this.displayDetailedUploadWindow = true;
    }

    closeDetailedUploadDialog() {
        this.displayDetailedUploadWindow = false;
    }

    ngOnInit() {
        this.contentMenu = [
            { label: 'Media', icon: 'fa fa-fw fa-play', routerLink: ['/workflow/worklist'] },
            { label: 'Series', icon: 'fa fa-fw fa-th', routerLink: ['/series'], routerLinkActiveOptions: { exact: true }},
            { label: 'Movies', icon: 'fa fa-fw fa-film', routerLink: ['/movies'] },
            { label: 'Channels', icon: 'fa fa-fw fa-tv', routerLink: ['/channels'] },
            { label: 'Search', icon: 'fa fa-fw fa-search', routerLink: ['/containers'] },
        ];
        this.newItemMenu = [
            { label: 'Featured', icon: 'fa fa-fw fa-certificate', routerLink: ['/featured'] },
        ];
        this.metricsMenu = [
            { label: 'Content Activity', icon: 'fa fa-fw fa-thermometer-half', routerLink: ['/dashboard'] },
            { label: 'System Status', icon: 'fa fa-fw fa-check-square', routerLink: ['/status'] },
        ];
        this.metricsReportsMenu = [
            { label: 'Metrics Dashboard', icon: 'fa fa-fw fa-signal', routerLink: ['/metrics/dashboard'] },
            { label: 'Metrics Reports', icon: 'fa fa-fw fa-line-chart', routerLink: ['/metrics/reports'] },
        ];
        this.testingReportsMenu = [
            { label: 'Testing Dashboard', icon: 'fa fa-fw fa-flask', routerLink: ['/testing/reports'] },
        ];
        this.configMenu = [
            { label: 'Applications', icon: 'fa fa-fw fa-rocket', routerLink: ['/applications'] },
            { label: 'Views', icon: 'fa fa-fw fa-clone', routerLink: ['/views'] },
            { label: 'Platforms', icon: 'fa fa-fw fa-desktop', routerLink: ['/settings/platforms'] },
            { label: 'Policies', icon: 'fa fa-fw fa-lock', routerLink: ['/settings/policies'] },
            { label: 'Integrations', icon: 'fa fa-fw fa-puzzle-piece', routerLink: ['/settings/integrations'] },
            { label: 'Pipelines', icon: 'fa fa-fw fa-paperclip', routerLink: ['/pipelines'], automationId: 'app-create-pipelines' },
            { label: 'Roles and Permissions', icon: 'fa fa-fw fa-users', routerLink: ['/roles'] },
            { label: 'Origins', icon: 'fa fa-fw fa-users', routerLink: ['/origins'] },
            { label: 'Providers', icon: 'fa fa-fw fa-users', routerLink: ['/providers'] },
            { label: 'Reports', icon: 'fa fa-fw fa-book', routerLink: ['/reports'], automationId: 'app-create-reports' },
            { label: 'Tests', icon: 'fa fa-fw fa-flask', routerLink: ['/settings/tests'] },
            { label: 'Notifications', icon: 'fa fa-fw fa-bell', routerLink: ['/settings/notifications'] },
        ];
        this.schemasMenu = [
            { label: 'Schemas', icon: 'fa fa-fw fa-table', routerLink: ['/settings/schemas'] },

        ];
        this.createMenu = [
            { label: 'Collection', icon: 'fa fa-fw fa-plus', routerLink: ['/collections', 'create'] },
            { label: 'Series ', icon: 'fa fa-fw fa-plus', routerLink: ['/series/create'], routerLinkActiveOptions: { exact: true }, automationId: 'app-create-series'},
            { label: 'Media', icon: 'fa fa-fw fa-plus', routerLink: ['/mediaobject', 'create'],  automationId: 'app-create-media'},
            { label: 'Document', icon: 'fa fa-fw fa-plus', routerLink: ['/document', 'create']},
            { label: 'Creative', icon: 'fa fa-fw fa-plus', routerLink: ['/commerce/creative/create']},
        ];

        this.adsMenu = [
            { label: 'Campaigns', icon: 'fa fa-fw fa-globe', routerLink: ['/commerce/campaign'], routerLinkActiveOptions: { exact: true } },
            { label: 'Content Groups', icon: 'fa fa-fw fa-briefcase', routerLink: ['/commerce/contentgroup'],  routerLinkActiveOptions: { exact: true }},
        ];

        this.createCommerceMenu = [
            { label: 'Advertisement', icon: 'fa fa-fw fa-plus', routerLink: ['/commerce/advertisement/create'],  routerLinkActiveOptions: { exact: true }, automationId: 'app-create-adverisement'},
            { label: 'Campaign', icon: 'fa fa-fw fa-plus', routerLink: ['/commerce/campaign/create'],  routerLinkActiveOptions: { exact: true }, automationId: 'app-create-campaign'},
            { label: 'Content Group', icon: 'fa fa-fw fa-plus', routerLink: ['/commerce/contentgroup/create'],  routerLinkActiveOptions: { exact: true }, automationId: 'app-create-content-group',},
        ];

        this.uploadMenu = [
            { label: 'Quick', icon: 'fa fa-fw fa-upload', command: () => this.showQuickUploadDialog() },
            { label: 'Detailed', icon: 'fa fa-fw fa-upload', command: () => this.showDetailedUploadDialog() },

        ];

        this.tagMenu = [
          { label: 'Bulk Assign', icon: 'fa fa-fw fa-plus', routerLink: ['/tags', 'assign']},
        ];

        /* Setting up non-permission-based menus first */
        this.commerceTabItems = [
            { label: 'ADS', items: this.adsMenu },
            { label: 'CREATE', items: this.createCommerceMenu }
        ]

        /* Setting up permission-based menus */
        this.activeOrigin = this.appContext['activeOrigin'];
        console.log('ACTIVE ORIGIN', this.activeOrigin);

        this.userPermSub = this.authService.userState.subscribe(p => {
            this.userPermissions = p.permissions;
            this.setUpGatedMenus();
        });

        this.activeOriginSub = this.appContext.activeOriginState$
            .subscribe ((val) => {
                console.log('ACTIVE ORIGIN CHANGE', val);
                if (val && val != this.activeOrigin) {
                    console.log('ACTIVE ORIGIN reset to', val);
                    this.activeOrigin = val;
                    this.setUpGatedMenus();
                }

        });

        /* Setting up subscription-based menus */
        this.isLoadingCurate = true;
        this.containerService
            .filterByTypeName('distribution', 'full', 1)
            .subscribe(
                res => {
                    console.log('distr:', res);
                    this.distributions = res;
                    this.distributions.forEach((element, index) => {
                        var distItem = {}
                        var viewItems = []
                        var views = element.containers.filter(subelement =>
                            subelement.type.name === 'view'
                        );
                        for (let view of views){
                            viewItems.push({ label: view.data.name, icon: 'fa fa-fw fa-square', routerLink:['/view/'+view.id] })
                        }
                        if (viewItems.length > 0) {
                            this.newItemMenu.push({ label: element.data.name, icon: 'fa fa-fw fa-th-large', items:viewItems })
                        } else {
                            this.newItemMenu.push({ label: element.data.name, icon: 'fa fa-fw fa-th-large' })

                        }
                        console.log('view:', views);
                    });

                },
                err => {
                    console.log('err', err);
                    this.error = err.statusText;
                },
                () => this.isLoadingCurate = false
            );

    }

    setUpGatedMenus() {
        /* Permission-based submenus */
        this.metricsTabItems = [];
        this.contentTabItems = [];
        this.metricsSubMenu = [];
        this.testingSubMenu = [];
        this.createSettingsMenu = [];
        this.configTabItems = [];
        this.configTagMenu = [];
        if (this.userPermissions) {
            // Active Origin not set at first log in, or after delete localStorage.
            this.appContext.setActiveOrigin(this.activeOrigin);
            this.activeOrigin = this.appContext.activeOrigin;

            let superuser = this.userPermissions[this.activeOrigin]?.view.superuser;

            /* Content Menus */
            this.contentTabItems.push({ label: 'CONTENT', items: this.contentMenu });

            if (this.userPermissions[this.activeOrigin]?.view.show?.includes('create') || superuser) {
                this.contentTabItems.push({ label: 'CREATE', items: this.createMenu });
            }
            if (this.userPermissions[this.activeOrigin]?.view.show?.includes('upload') || superuser) {
                this.contentTabItems.push({ label: 'UPLOAD', items: this.uploadMenu });
            }
            if (this.userPermissions[this.activeOrigin]?.view.show?.includes('bulktag') || superuser) {
                this.contentTabItems.push({ label: 'TAGS', items: this.tagMenu });
            }

            /* Metrics Menus */
            if (this.userPermissions[this.activeOrigin]?.view.show?.includes('reports') || superuser) {
                this.metricsSubMenu.push(...this.metricsReportsMenu);
            }

            if (this.userPermissions[this.activeOrigin]?.view.show?.includes('metrics') || superuser) {
                this.metricsSubMenu.push(...this.metricsMenu);
            }
            
            this.metricsTabItems = [{label: 'METRICS', items: this.metricsSubMenu}];
          
            if (this.userPermissions[this.activeOrigin]?.view.show?.includes('run_tests') || superuser) {
                this.testingSubMenu.push(...this.testingReportsMenu);
                this.metricsTabItems.push({label: 'TESTING', items: this.testingSubMenu});
            }

            /* Settings Menus */
            if (this.userPermissions[this.activeOrigin]?.view.show?.includes('settings') || superuser) {
                let entityTypesLinkFound = this.configMenu.some(obj=>obj.label=='Entity Types');
                let schemasLinkFound = this.configMenu.some(obj=>obj.label=='Schemas');
                let workflowLinkFound = this.configMenu.some(obj=>obj.label=='Workflows');
                let personsLinkFound = this.configMenu.some(obj=>obj.label=='Persons');
                let testLinkFound = this.configMenu.some(obj=>obj.label=='Tests');
                let locksLinkFound = this.configMenu.some(obj=>obj.label=='Locks');
                if (this.appContext.activeOrigin === 'system' && this.userPermissions && this.userPermissions['system']?.view.show?.includes('beta') || superuser)  {
                    if (!entityTypesLinkFound && (this.userPermissions['system']?.view.show?.includes('entityType') || this.userPermissions['system'].view.superuser)) {
                        this.configMenu.push({ label: 'Entity Types', icon: 'fa fa-fw fa-book', routerLink: ['/settings/entitytypes']})
                    }
                }
                else {
                    if (entityTypesLinkFound){
                        this.configMenu.splice(this.configMenu.findIndex(obj=>obj.label=='Entity Types'), 1);
                        if (this.router.url === '/settings/entitytypes') {
                            this.location.back();
                        }
                    }
                }

                if (!workflowLinkFound && (this.userPermissions[this.activeOrigin]?.view.show?.includes('workflow') || superuser)) {
                    this.configMenu.push({ label: 'Workflows', icon: 'pi pi-cog', routerLink: ['/settings/workflows'] });
                } else if (workflowLinkFound && !(this.userPermissions[this.activeOrigin]?.view.show?.includes('workflow') || superuser)) {
                    // Removes link from menu if was added from other Origin before.
                    this.configMenu.splice(this.configMenu.findIndex(obj=>obj.label=='Workflows'), 1);
                }

                if (!schemasLinkFound && (this.userPermissions[this.activeOrigin]?.view.show?.includes('schema') || superuser)) {
                    this.configMenu.push({ label: 'Schemas', icon: 'fa fa-fw fa-table', routerLink: ['/settings/schemas'] });
                } else if (schemasLinkFound && !(this.userPermissions[this.activeOrigin]?.view.show?.includes('schema') || superuser)) {
                    this.configMenu.splice(this.configMenu.findIndex(obj=>obj.label=='Schemas'), 1);
                }

                if (!personsLinkFound && (this.userPermissions[this.activeOrigin]?.view.show?.includes('persons') || superuser)) {
                    this.configMenu.push({ label: 'Persons', icon: 'fa fa-user-secret', routerLink: ['/persons'] });
                } else if (personsLinkFound && !(this.userPermissions[this.activeOrigin]?.view.show?.includes('persons') || superuser)) {
                    this.configMenu.splice(this.configMenu.findIndex(obj=>obj.label=='Persons'), 1);
                }

                if (!testLinkFound && (this.userPermissions[this.activeOrigin]?.view.show?.includes('tests') || superuser)) {
                    this.configMenu.push({ label: 'Tests', icon: 'fa fa-fw fa-flask', routerLink: ['/settings/tests'] });
                } else if (testLinkFound && !(this.userPermissions[this.activeOrigin]?.view.show?.includes('tests') || superuser)) {
                    this.configMenu.splice(this.configMenu.findIndex(obj=>obj.label=='Tests'), 1);
                }

                /* Locks Menu */
                if (!locksLinkFound && (this.userPermissions[this.activeOrigin]?.view.show?.includes('locks') || superuser)) {
                  this.configMenu.push({ label: 'Locks', icon: 'fa fa-lock', routerLink: ['/locks'] });
                } else if (locksLinkFound && !(this.userPermissions[this.activeOrigin]?.view.show?.includes('locks') || superuser)) {
                  this.configMenu.splice(this.configMenu.findIndex(obj=>obj.label=='Locks'), 1);
                }

                this.configTabItems.push({ label: 'SETTINGS', items: this.configMenu });
                this.createSettingsMenu.push({ label: 'Container', icon: 'fa fa-fw fa-plus', routerLink: ['/containers', 'create'], automationId: 'app-create-containers' });
                this.configTabItems.push({ label: 'CREATE', items: this.createSettingsMenu });
            }

            /* Tags Menu */
            if (this.userPermissions[this.activeOrigin]?.view.show?.includes('tag') || superuser) {
                this.configTagMenu.push({ label: 'Container Tags', icon: 'fa fa-fw fa-tag', routerLink: ['/tags'] });
                if (!this.configTabItems.some(item => item.label === 'TAGS')) {
                  this.configTabItems.push({ label: 'TAGS', items: this.configTagMenu });
                }
            }

            /* Resource Tags Menu */
            if (this.userPermissions[this.activeOrigin]?.view.show?.includes('resourcetag') || superuser) {
              this.configTagMenu.push({ label: 'Resource Tags', icon: 'fa fa-fw fa-tag', routerLink: ['/resourcetags'] });
              if (!this.configTabItems.some(item => item.label === 'TAGS')) {
                this.configTabItems.push({ label: 'TAGS', items: this.configTagMenu });
              }

            }

        }
    }

    ngOnDestroy() {
        this.userPermSub.unsubscribe();
        this.activeOriginSub.unsubscribe();
    }

}
