import { Component, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { MessageService } from 'primeng/api';
import { Container } from '../../../content/models/container';
import { WorkflowFormComponent } from '../../forms/workflow-form/workflow-form.component';
import { ContainerService } from '../../../content/services/container';
import { Router } from '@angular/router';



@Component({
  selector: 'workflow-create',
  providers: [
    ContainerService
],
  templateUrl: './workflow-create.component.html',
  styleUrls: []
})
export class WorkflowCreateComponent implements OnInit {
  @ViewChild('form') form: WorkflowFormComponent;
  public container: Container;
  public isLoading:boolean = false

  constructor(
    private location: Location,
    private router: Router,
    private containerService: ContainerService,
    private msgService: MessageService,
   ) { }

  ngOnInit() {
    this.container = new Container();

  }

  onSubmit(cnt: Container){
    this.isLoading = true
    this.containerService.save(cnt).subscribe(
      res => this.router.navigate(['/workflow', res.id], { replaceUrl: true }),
      err => {
        console.log(err)
        this.isLoading = false
      },
      () => this.isLoading = false
    );
  }

  onCancel(e) {
   this.location.back()
  }

  onFail(e){
    this.msgService.add(e);
  }

}
