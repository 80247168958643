import { Component, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { MessageService } from 'primeng/api';
import { ResourceTag } from '../../../content/models';
import { ResourceTagFormComponent } from '../../forms/resource-tag-form/resource-tag-form.component';
import { ResourceTagService } from '../../../content/services';
import { Router } from '@angular/router';



@Component({
  selector: 'resource-tag-create',
  providers: [
    ResourceTagService
],
  templateUrl: './resource-tag-create.component.html',
  styleUrls: []
})
export class ResourceTagCreateComponent implements OnInit {
  @ViewChild('form') form: ResourceTagFormComponent;
  public tag: ResourceTag;
  public isLoading:boolean = false

  constructor(
    private location: Location,
    private router: Router,
    private resourcetagService: ResourceTagService,
    private msgService: MessageService,
   ) { }

  ngOnInit() {
    this.tag = new ResourceTag();

  }

  onSubmit(tag: ResourceTag){
    this.isLoading = true
    this.resourcetagService.save(tag).subscribe(
      res => this.router.navigate(['/resourcetag', res.id], { replaceUrl: true }),
      err => {
        console.log(err)
        this.isLoading = false
      },
      () => this.isLoading = false
    );
  }

  onCancel(e) {
   this.location.back()
  }

  onFail(e){
    this.msgService.add({ severity: 'error', summary: 'Error', detail: 'Please enter the tag value.' });
  }

}
