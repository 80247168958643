<spinner *ngIf="isLoading"></spinner>
<form *ngIf="!isLoading" #ngForm="ngForm" (submit)="onSubmit($event)">
    <div class="ui-g">
        <p-toolbar class="ui-g-12">
          <div class="ui-g-6">
            <button pButton type="button" label="Cancel" (click)="onCancel($event)" class="p-button-danger" icon="pi pi-arrow-circle-left" style="margin-right:4px;" data-element-id="app-settings-forms-workflow-form-cancel"></button>
          </div>
          <div class="ui-g-6" style="text-align:right">
            <container-lock [container]="container" (containerLocked)="saveBtn.disabled=$event" *ngxPermissionsOnly="['view.show.beta']" style="margin:4px;" data-element-id="app-settings-forms-workflow-form-lock"></container-lock>
            <button pButton #saveBtn type="submit" label="Save" icon="pi pi-check" data-element-id="app-settings-forms-workflow-form-save"></button>
          </div>
        </p-toolbar>

        <p-panel class="ui-g-12 ui-md-12 ui-lg-6">
          <p-header>
              <span class="p-panel-title" style="font-size:16px;">Basic Information</span>
          </p-header>

          <div style="padding:0.5em;overflow:hidden;">
              <div class="ui-sm-12 ui-g-6">
                  <label for="name">Name</label>
                  <input type="text" class="form-control" name="name" placeholder="Required" [(ngModel)]="container.data.name" data-element-id="app-settings-forms-workflow-form-name">
              </div>
              <div class="ui-sm-12 ui-g-6">
                  <label for="label">Label</label>
                  <input type="text" class="form-control" name="label" [(ngModel)]="container.data.label" data-element-id="app-settings-forms-workflow-form-label">
              </div>
              <div class="ui-sm-12 ui-g-6">
                <label for="description">Description</label>
                <input type="text" class="form-control" name="description" placeholder="Required" [(ngModel)]="container.data.description" data-element-id="app-settings-forms-workflow-form-description">
              </div>
              <div class="ui-g-12 ui-md-6">
                <label for="status">Status</label>
                <select type="text" class="form-control" [(ngModel)]="container.status" name="status" data-element-id="app-settings-forms-workflow-form-status">
                  <option *ngFor="let status of statusList" [value]="status">{{ status }}</option>
                </select>
              </div>
              <div class="ui-g-12 ui-md-6">
                <label for="subtype">Sub Type</label>
                  <input type="text" class="form-control" name="subtype" [(ngModel)]="container.data.subtype" data-element-id="app-settings-forms-workflow-form-subtype">
              </div>
          </div>
        </p-panel>

        <p-panel class="ui-g-12 ui-md-12 ui-lg-6">
          <p-header>
              <span class="p-panel-title" style="font-size:16px;">Identity Information</span>
          </p-header>

          <div style="padding:0.5em;overflow:hidden;">
              <div class="ui-g-6">
                  <label for="type">Type</label>
                  <input type="text" class="form-control" disabled [(ngModel)]="entityType.name" name="type" data-element-id="app-settings-forms-workflow-form-type">
              </div>
              <div class="ui-g-6">
                  <label for="guid">GUID</label>
                  <input type="text" class="form-control" readonly [(ngModel)]="container.guid" name="guid" data-element-id="app-settings-forms-workflow-form-guid">
              </div>
              <div class="ui-g-6">
                <label for="origin" aria-readonly="true">Origin</label>
                <input type="text" class="form-control" name="origin" readonly [(ngModel)]="container.origin" data-element-id="app-settings-forms-workflow-form-origin">
              </div>
          </div>
      </p-panel>
  </div>
</form>
