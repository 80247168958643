<page-header title="Edit Pipeline{{!isLoading && container ? ': ' + container.data['name'] : ''}}" [showSpinner]="isLoading" [error]="error?.statusText"></page-header>
<div class="card card-w-title">
    <h1>Edit Pipeline{{!isLoading && container ? ': ' + container.data['name'] : ''}}</h1>

    <!--<p-growl [(value)]="requiredMsgs"></p-growl> -->
    <p-messages [(value)]="requiredMsgs"></p-messages>
    <p-messages [(value)]="msgs"></p-messages>

    <div class="ui-g card-content" *ngIf="container && !isLoading">
        <pipeline-form #form class="ui-g-12" [container]="container" (formSubmit)="onSubmit($event)" (formFailed)="onFailure($event)" (formCancelled)="onBack($event)"></pipeline-form>

        <hr>

        <p-panel class="ui-g-12 ui-md-12 ui-lg-12">
            <!-- TODO: move to worker-table component -->
            <p-header>
                <span class="p-panel-title" style="font-size:16px;">Workers</span>
                <button pButton type="button" label="Add" [routerLink]="['/containers', container.id, 'relate', 'worker']" icon="pi pi-plus" style="float:right;height:100%;"></button>
            </p-header>
            <p-table #dt [value]="workers"
                [rows]="20"
                [paginator]="true"
                paginatorPosition="bottom"
                selectionMode="single"
                (onRowSelect)="onRowSelect($event)"
                [(selection)]="container"
                styleClass="p-datatable-gridlines"
                [responsive]="true">
                <ng-template pTemplate="header">
                    <tr>
                        <th style="width:25%">Name</th>
                        <th>Module Id</th>
                        <th>Worker Id</th>
                        <th style="width:8%">Origin</th>
                        <!-- <th style="width:8%">Execution</th> -->
                        <!-- <th style="width:8%">Type</th> -->
                        <th style="width:5%"></th>
                        <th style="width:5%"></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-worker>
                    <tr>
                        <td>{{worker.data.name}}</td>
                        <td>{{worker.data.bundle_id}}</td>
                        <td>{{worker.guid}}</td>
                        <td>{{worker.origin}}</td>
                        <!-- <td>{{worker.data.execution_type}}</td> -->
                        <!-- <td>{{worker.data.worker_type}}</td> -->
                        <td>
                            <button pButton type="button" class="p-button-primary" label="Edit" [routerLink]="['/workers', worker.id]" [disabled]="worker.origin != container.origin"></button>
                        </td>
                        <td>
                            <button pButton type="button" class="p-button-danger" icon="pi pi-times" style="font-weight:600;display:block;margin:auto;" (click)="onRemove($event, worker)"></button>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
            <!--
            <container-datarow [containers]="workers" [parentContainer]="container" title="Workers" filterBy="data.name" (reorder)="onReorder($event)">
                <button pButton type="button" label="Add" [routerLink]="['/containers', container?.id, 'relate', 'worker']" icon="pi pi-plus" style="float:right;height:100%;"></button>
            </container-datarow>
             -->
        </p-panel>

        <p-panel class="ui-g-6 ui-md-6 ui-lg-6">
            <p-header>
                <span class="p-panel-title" style="font-size:16px;">Inputs</span>
            </p-header>
            <json-editor #inputs [options]="editorOptionsInputs" [data]="inputsObject"></json-editor>
        </p-panel>

        <p-panel class="ui-g-6 ui-md-6 ui-lg-6">
            <p-header>
                <span class="p-panel-title" style="font-size:16px;">Results</span>
            </p-header>
            <json-editor #results [options]="editorOptionsResults" [data]="resultsObject"></json-editor>
        </p-panel>

        <p-panel class="ui-g-12 ui-md-12 ui-lg-12">
            <p-header>
                <span class="p-panel-title" style="font-size:16px;">Steps</span>
            </p-header>
            <json-editor #steps [options]="editorOptionsSteps" [data]="stepsArray"></json-editor>
        </p-panel>

        <hr>

        <div class="ui-g-12">
            <object-advancedview [object]="container" title="Advanced Editing"></object-advancedview>
        </div>
    </div>
</div>
