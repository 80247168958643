import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { HttpResponse } from '@angular/common/http';
import { TestSuiteFormComponent } from './../../forms/test-suite-form/test-suite-form.component';
import { ContainerService } from '../../../content/services/container/container.service'
import { Container } from '../../../content/models/container';
import { Message } from 'primeng/api';

@Component({
    selector: 'test-suite-create',
    providers: [
        ContainerService
    ],
    templateUrl: 'test-suite-create.component.html',
    styleUrls: ['test-suite-create.component.css']
})
export class TestSuiteCreateComponent implements OnInit, OnDestroy {
    @ViewChild('form') form: TestSuiteFormComponent;
    private sub: any;
    public container: Container = new Container();
    public error: HttpResponse<any>;
    public isLoading = false;
    private msgs: Message[] = [];
    private requiredMsgs: Message[] = [];

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private location: Location,
        public containerService: ContainerService
    ) {}

    ngOnInit() {
        console.log('Test Suite create initialized');
    }

    ngOnDestroy() {

    }

    onSubmit(e) {
        console.log('submit', e.model);
        this.container.data['type'] = 'builtin';
        console.log(this.container.data);

        this.container.status = 'published';
        this.containerService
            .save(this.container)
            .subscribe(
                res => {
                    console.log(res);
                    this.router.navigate(['/testsuite', res.id], { replaceUrl: true });
                },
                err => {
                    console.log(err);
                    this.error = err.statusText;
                },
                () => this.isLoading = false
            );
    }

    onBack(e) {
        if (!this.form || this.form.isPristine() || confirm('You may have unsaved changes that will not be saved if you leave this page. Are you sure you want to leave this page?')) {
            this.location.back();
        }
    }
    onCancel(e) {
        if (!this.form || this.form.isPristine() || confirm('You may have unsaved changes that will not be saved if you leave this page. Are you sure you want to leave this page?')) {
          this.location.back();
        }
    }
    onFailure(e) {
        this.requiredMsgs = [];
        this.requiredMsgs.push({ severity: 'error', summary: 'Changes Were Not Saved', detail: 'There are errors in the form, please review and fix errors before saving.' });
    }
}
