<div class="card card-w-title top-level-card">
    <h1>Integration Tests</h1>
        <p-toolbar>
            <div class="ui-g">
                <div class="ui-g-4">
                    <input *ngIf="!collapsed" type="text" pInputText placeholder="Filter" (keyup)="dt.filterGlobal($event.target.value.toLowerCase(), 'contains')" style="text-align:center;height:100%;width:100%;"/>
                </div>
                <div class="ui-g-4">
                    <p-dropdown [options]="statusList" 
                                (onChange)="onChangedFilteredStatus($event)" 
                                placeholder="No Status Filter" 
                                optionLabel="value" 
                                [showClear]="true" 
                                [ngModel]="selectedStatus" 
                                [style]="{'height':'100%','width':'100%'}">
                    </p-dropdown>
                </div>
                <div class="ui-g-4">
                    <button pButton type="button" label="Create" [routerLink]="['/testsuite', 'create']" icon="pi pi-plus" class="p-button-success" style="height:100%;float:right;" data-element-id="app-settings-components-tests-create"></button>
                </div>
                <spinner *ngIf="isLoading"></spinner>
            </div>
        </p-toolbar>
    <p-table [columns]="columns" #dt 
            [value]="containers"  
            [autoLayout]="true" 
            [globalFilterFields]="['data.label', 'guid', 'data.type', 'data.description', 'status']" 
            [rows]="10" 
            [paginator]="true" 
            paginatorPosition="both" 
            dataKey="guid" 
            selectionMode="single" 
            (onRowSelect)="onRowSelect($event)" 
            [(selection)]="container" 
            [responsive]="true"
            styleClass="p-datatable-gridlines"
            expandableRows="true">
        <ng-template pTemplate="header" let-columns="columns">
            <tr>
                <th></th>
                <th>Title</th>
                <th>GUID</th>
                <th>Type</th>
                <th>Status</th>
                <th></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-testsuite let-expanded="expanded" let-columns="columns">
            <tr *ngIf="!isLoading">
                <td>
                    <a [pRowToggler]="testsuite">
                        <i [ngClass]="expanded ? 'fa fa-fw fa-chevron-circle-down' : 'fa fa-fw fa-chevron-circle-right'"></i>
                    </a>
                </td>
                <td>{{testsuite.data.label}}</td>
                <td style="word-break: break-all;">{{testsuite.guid}}</td>
                <td class="text-center"><div class="label label-default">{{testsuite.data.type}}</div></td>
                <td class="text-center"><div class="label" [ngClass]="statusLabelClass(testsuite.status)">{{testsuite.status|titlecase}}</div></td>
                <td>
                    <div class="text-center">
                        <button pButton type="button" label="Edit" class="p-button-primary" [routerLink]="['/testsuite', testsuite.id]" icon="pi pi-pencil"></button>
                    </div>
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="rowexpansion" let-testsuite let-columns="columns">
        <tr>
            <td [attr.colspan]="columns.length + 6">
                <div class="ui-g p-fluid" style="font-size:14px;padding:0.2em">
                    <div class="ui-g" style="text-align:center">
                        <img style="max-height:8em;width:auto;max-width:100%">
                    </div>
                    <div class="ui-g-4">
                        <div>Description</div>
                        <p-scrollPanel [style]="{width: '100%', height: '6em'}">
                            {{testsuite.data.description}}
                        </p-scrollPanel>
                    </div>
                </div>
            </td>
        </tr>
        </ng-template>
    </p-table>
</div>
