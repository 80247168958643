<div class="card card-w-title top-level-card">
    <h1>Providers</h1>
        <p-toolbar>
            <div class="ui-g">
                <div class="ui-g-4">
                    <input *ngIf="!collapsed" type="text" pInputText placeholder="Filter" (keyup)=" dt.filterGlobal($event.target.value.toLowerCase(), 'contains')" class="filter-bar"/>
                </div>
                <div class="ui-g-4">
                    <p-dropdown [options]="statusList" (onChange)="onChangedFilteredStatus($event)" placeholder="No Status Filter" optionLabel="value" [showClear]="true" [ngModel]="selectedStatus" [style]="{'height':'100%','width':'100%'}"></p-dropdown>
                </div>
                <div class="ui-g-4">
                    <button pButton type="button" label="Create" [routerLink]="['/providers', 'create']" icon="pi pi-plus" class="p-button-success" style="height:100%;float:right;" data-element-id="app-settings-components-providers-create"></button>
                </div>
                <spinner *ngIf="isLoading"></spinner>
            </div> 
        </p-toolbar>
                    
    <p-table [columns]="columns" #dt 
            [value]="containers"  
            [autoLayout]="true" 
            [globalFilterFields]="['data.name', 'guid', 'data.description', 'status']" 
            [rows]="10" 
            [paginator]="true" 
            paginatorPosition="both" 
            dataKey="guid" 
            selectionMode="single" 
            (onRowSelect)="onRowSelect($event)" 
            [(selection)]="container" 
            [responsive]="true" 
            styleClass="p-datatable-gridlines"
            expandableRows="true">
        <ng-template pTemplate="header">
            <tr>
                <th>Name</th>
                <th>Guid</th>
                <th>Description</th>
                <th>Status</th>
                <th></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-provider>
            <tr>
                <td>{{provider.data.name}}</td>
                <td style="word-break: break-all;">{{provider.guid}}</td>
                <td>{{provider.data.description}}</td>
                <td class="text-center"><div class="label" [ngClass]="statusLabelClass(provider.status)">{{provider.status|titlecase}}</div></td>
                <td>
                    <div class="text-center">
                        <button pButton type="button" class="p-button-primary" label="Edit" [routerLink]="['/providers', provider.id]" icon="pi pi-pencil"></button>
                    </div>
                </td>
            </tr>
        </ng-template>
    </p-table>
</div>
