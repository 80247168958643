<div class="card card-w-title top-level-card">
  <h1>Notifications</h1>
  <div class="ui-g"><p-messages styleClass="w-100"></p-messages></div>
    <p-table #nt  [value]="notificationContainers"
                  [globalFilterFields]="['data.notification_type','data.mode','data.name']"
                  [rows]="20"
                  [paginator]="true"
                  paginatorPosition="bottom"
                  selectionMode="single"
                  styleClass="p-datatable-gridlines"
                  [responsive]="true">
        <ng-template pTemplate="caption">
          <div class="clearfix">
            <div class="ui-g-12 ui-md-4">
              <input type="text" id="type" name="type" pInputText placeholder="Filter"
                    (keyup)="nt.filterGlobal($event.target.value.toLowerCase(), 'contains')"
                    style="width:100%"
              />
            </div>
            <div class="ui-g-12 ui-md-4">
              <p-dropdown appendTo="body" [options]="statusList" (onChange)="onChangedFilteredStatus($event)" placeholder="No Status Filter" optionLabel="value" [showClear]="true" [ngModel]="selectedStatus" [style]="{'height':'100%','width':'100%'}"></p-dropdown>
            </div>
            <div class="ui-g-12 ui-md-4">
              <button pButton type="button" label="Create" [routerLink]="['/notification', 'create']" icon="pi pi-plus" class="p-button-success" style="height:100%;float:right;"></button>
            </div>
          </div>
        </ng-template>
        <ng-template pTemplate="header">
            <tr>
              <th>Type</th>
              <th>Name</th>
              <th>Mode</th>
              <th>Namespace</th>
              <th>Frequency</th>
              <th style="width:65px"></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-notification>
            <tr>
              <td>{{notification.data.notification_type}}</td>
              <td>{{notification.data.name}}</td>
              <td>{{notification.data.mode}}</td>
              <td>{{notification.data.namespace}}</td>
              <td>{{notification.data.evaluate_frequency}}</td>
              <td style="text-align: center"><button pButton type="button" class="p-button-primary" label="Edit" [routerLink]="['/notification', notification.id]"></button></td>
            </tr>
        </ng-template>
    </p-table>
