import { Routes, RouterModule } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';
import { LandingComponent } from './landing/landing.component';
import { FeaturedComponent } from './content/components/featured/featured.component';
import { SeriesComponent } from './content/components/series/series.component';
import { ChannelsComponent } from './content/components/channels/channels.component';
import { ContainerRootComponent } from './content/components/container-root/container-root.component';
import { ContainerEditComponent } from './content/components/container-edit/container-edit.component';
import { ContainerCreateComponent } from './content/components/container-create/container-create.component';
import { ContainerListComponent } from './content/components/container-list/container-list.component';
import { ContainerRowComponent } from './content/components/container-row/container-row.component';
import { ContainerRelateComponent } from './content/components/container-relate/container-relate.component';
import { ContainerRelateThroughComponent } from './content/components/container-relate-through/container-relate-through.component';
import { ContainerMultiParentRelateComponent } from './content/components/container-multi-parent-relate/container-multi-parent-relate.component';

import { ResourceRootComponent } from './content/components/resource-root/resource-root.component';
import { ResourceCreateComponent } from './content/components/resource-create/resource-create.component';
import { ResourceEditComponent } from './content/components/resource-edit/resource-edit.component';
import { ResourceUploadComponent } from './content/components/resource-upload/resource-upload.component';

import { StatusComponent } from './status/status.component';
import { LoginComponent } from './auth/login.component';
import { LogoutComponent } from './auth/logout.component';
import { ExternalLoginComponent } from './auth/login-external.component';
import { AuthGuard } from './auth/auth-guard.service';

import { ApplicationsComponent } from './settings/components/applications/applications.component';
import { ApplicationCreateComponent } from './settings/components/application-create/application-create.component';
import { ApplicationEditComponent } from './settings/components/application-edit/application-edit.component';

import { DistributionCreateComponent } from './settings/components/distribution-create/distribution-create.component';
import { DistributionEditComponent } from './settings/components/distribution-edit/distribution-edit.component';

import { VersionCreateComponent } from './settings/components/version-create/version-create.component';
import { VersionEditComponent } from './settings/components/version-edit/version-edit.component';

import { ConfigurationCreateComponent } from './settings/components/configuration-create/configuration-create.component';
import { ConfigurationEditComponent } from './settings/components/configuration-edit/configuration-edit.component';

import { SettingsPoliciesComponent } from './settings/components/policies/settings-policies.component';
import { SettingsIntegrationsComponent } from './settings/components/integrations/settings-integrations.component';
import { SettingsPlatformsComponent } from './settings/components/platforms/settings-platforms.component';

import { SettingsOriginsComponent } from './settings/components/origins/settings-origins.component';
import { OriginCreateComponent } from './settings/components/origin-create/origin-create.component';
import { OriginEditComponent } from './settings/components/origin-edit/origin-edit.component';

import { SettingsProvidersComponent } from './settings/components/providers/settings-providers.component';
import { ProviderCreateComponent } from './settings/components/provider-create/provider-create.component';
import { ProviderEditComponent } from './settings/components/provider-edit/provider-edit.component';

import { TestSuiteCreateComponent } from './settings/components/test-suite-create/test-suite-create.component';
import { TestSuiteEditComponent } from './settings/components/test-suite-edit/test-suite-edit.component';
import { SettingsTestsComponent } from './settings/components/tests/settings-tests.component';

import { TestCreateComponent } from './settings/components/test-create/test-create.component';
import { TestEditComponent } from './settings/components/test-edit/test-edit.component';

import { PlatformCreateComponent } from './content/components/platform-create/platform-create.component';
import { PlatformFormComponent } from './content/forms/platform-form/platform-form.component';
import { PlatformEditComponent } from './content/components/platform-edit/platform-edit.component';

import { PolicyCreateComponent } from './content/components/policy-create/policy-create.component';
import { PolicyEditComponent } from './content/components/policy-edit/policy-edit.component';

import { SettingsReportsComponent } from './settings/components/reports/settings-reports.component';
import { ReportEditComponent } from './settings/components/report-edit/report-edit.component';
import { ReportCreateComponent } from './settings/components/report-create/report-create.component';

import { ServerCreateComponent } from './content/components/server-create/server-create.component';
import { ServerEditComponent } from './content/components/server-edit/server-edit.component';


import { WorklistComponent } from './workflow/components/worklist/worklist.component';

import { HistoryListComponent } from './content/components/history-list/history-list.component';
import { HistoryDiffComponent } from './content/components/history-diff/history-diff.component';
import { HistoryRevertComponent } from './content/components/history-revert/history-revert.component';

import { CatalogCreateComponent } from './content/components/catalog-create/catalog-create.component';
import { CatalogFormComponent } from './content/forms/catalog-form/catalog-form.component';
import { CatalogEditComponent } from './content/components/catalog-edit/catalog-edit.component';

import { DocumentEditComponent } from './content/components/document-edit/document-edit.component';
import { DocumentCreateComponent } from './content/components/document-create/document-create.component';

import { MoviesComponent } from './content/components/movies/movies.component';
import { MoviesEditComponent } from './content/components/movies-edit/movies-edit.component';

import { EpisodeEditComponent } from './content/components/episode-edit/episode-edit.component';

import { ChannelEditComponent } from './content/components/channel-edit/channel-edit.component';

import { MediaEditComponent } from './content/components/media-edit/media-edit.component';

import { DashboardComponent } from './dashboard/components/dashboard/dashboard.component';

import { SeriesEditComponent } from './content/components/series-edit/series-edit.component';
import { SeriesCreateComponent } from './content/components/series-create/series-create.component';

import { SeasonEditComponent } from './content/components/season-edit/season-edit.component';
import { SeasonCreateComponent } from './content/components/season-create/season-create.component';

import { ToolsComponent } from './tools/tools.component';

import { CollectionEditComponent } from './content/components/collection-edit/collection-edit.component';
import { CollectionCreateComponent } from './content/components/collection-create/collection-create.component';
import { CollectionContainerComponent } from './content/components/collection-container/collection-container.component';


import { PreviewFeaturedContentComponent } from './preview/components/featured/preview-featured-content.component';
import { PreviewSeriesDetailComponent } from './preview/components/series/preview-series-detail.component';

import { ViewsComponent } from './settings/components/views/views.component';
import { ViewCreateComponent } from './settings/components/view-create/view-create.component';
import { ViewEditComponent } from './settings/components/view-edit/view-edit.component';

import { PersonsComponent } from './settings/components/persons/persons.component';
import { PersonCreateComponent } from './settings/components/person-create/person-create.component';
import { PersonEditComponent } from './settings/components/person-edit/person-edit.component';

import { CastCreateComponent } from './settings/components/cast-create/cast-create.component';
import { CastEditComponent } from './settings/components/cast-edit/cast-edit.component';

import { MetricsDashboardComponent } from './metrics/components/metrics-dashboard/metrics-dashboard.component';
import { MetricsReportsComponent } from './metrics/components/metrics-reports/metrics-reports.component';

import { TestingReportsComponent } from './testing/components/testing-reports/testing-reports.component';


import { PipelinesComponent } from './settings/components/pipelines/pipelines.component';
import { PipelineCreateComponent } from './settings/components/pipeline-create/pipeline-create.component';
import { PipelineEditComponent } from './settings/components/pipeline-edit/pipeline-edit.component';
import { PipelineFormComponent } from './settings/forms/pipeline-form/pipeline-form.component';

import { ProfileCreateComponent } from './settings/components/profile-create/profile-create.component';
import { ProfileEditComponent } from './settings/components/profile-edit/profile-edit.component';
import { ProfileFormComponent } from './settings/forms/profile-form/profile-form.component';

import { UserProfileCreateComponent } from './settings/components/user-profile-create/user-profile-create.component';
import { UserProfileEditComponent } from './settings/components/user-profile-edit/user-profile-edit.component';

import { WorkerCreateComponent } from './settings/components/worker-create/worker-create.component';
import { WorkerEditComponent } from './settings/components/worker-edit/worker-edit.component';
import { WorkerFormComponent } from './settings/forms/worker-form/worker-form.component';

import { RolesComponent } from './settings/components/roles/roles.component';
import { RoleCreateComponent } from './settings/components/role-create/role-create.component';
import { RoleEditComponent } from './settings/components/role-edit/role-edit.component';
import { RoleFormComponent } from './settings/forms/role-form/role-form.component';

import { AdvertisementCreateComponent } from './commerce/components/advertisement/advertisement-create/advertisement-create.component';
import { AdvertisementEditComponent } from './commerce/components/advertisement/advertisement-edit/advertisement-edit.component';

import { CACampaignWorklistComponent } from './commerce/components/contextual-ads-campaign/contextual-ads-campaign.component';
import { CACampaignEditComponent } from './commerce/components/campaign-edit/campaign-edit.component';
import { CACampaignCreateComponent} from './commerce/components/campaign-create/campaign-create.component';

import { CAContextWorklistComponent } from './commerce/components/contextual-ads-context/contextual-ads-context.component';
import { CAContentGroupEditComponent } from './commerce/components/content-group-edit/content-group-edit.component';
import { CAContentGroupCreateComponent } from './commerce/components/content-group-create/content-group-create.component';

import { CAMomentEditComponent } from './commerce/components/moment-edit/moment-edit.component';

import { MediaObjectCreateComponent } from './content/components/media-object-create/media-object-create.component';

import { TagsComponent } from './settings/components/tags/tags.component';
import { TagCreateComponent } from './settings/components/tag-create/tag-create.component';
import { TagEditComponent } from './settings/components/tag-edit/tag-edit.component';
import { TagsAssignComponent } from './content/components/tags-assign/tags-assign.component';

import { SettingsSchemasComponent } from './settings/components/schemas/schemas.component';
import { SchemaCreateComponent } from './settings/components/schema-create/schema-create.component';
import { SchemaEditComponent } from './settings/components/schema-edit/schema-edit.component';
import { SchemaRelateComponent } from './settings/components/schema-relate/schema-relate.component';

import { EntityTypeComponent } from './settings/components/entitytypes/entitytype.component';
import { EntitytypeEditComponent } from './settings/components/entitytype-edit/entitytype-edit.component';

import { NotificationsComponent } from './settings/components/notifications/notifications.component';
import { NotificationCreateComponent } from './settings/components/notification-create/notification-create.component';
import { NotificationEditComponent } from './settings/components/notification-edit/notification-edit.component';

import { WorkflowsComponent } from './settings/components/workflows/workflows.component';
import { WorkflowCreateComponent } from './settings/components/workflow-create/workflow-create.component';
import { WorkflowEditComponent } from './settings/components/workflow-edit/workflow-edit.component';

import { WorkflowStateSegmentCreateComponent } from './content/components/workflow-state-segment-create/workflow-state-segment-create.component';

import { SegmentEditComponent } from './content/components/segment-edit/segment-edit.component';
import { WorkflowSegmentEditComponent } from './settings/components/workflow-segment-edit/workflow-segment-edit.component';
import { SegmentCreateComponent } from './content/components/segment-create/segment-create.component';

import { FlightCreateComponent } from './commerce/components/flight-create/flight-create.component';
import { FlightEditComponent } from './commerce/components/flight-edit/flight-edit.component';

import { CreativeCreateComponent } from './commerce/components/container-creative-create/container-creative-create.component';
import { CreativeEditComponent } from './commerce/components/container-creative-edit/container-creative-edit.component';

import { ContainerGuidComponent } from './content/components/container-guid/container-guid.component';
import { LocksComponent } from './settings/components/locks/locks.component';

import { ResourceTagsComponent } from './settings/components/resource-tags/resource.tags.component';
import { ResourceTagCreateComponent } from './settings/components/resource-tag-create/resource-tag-create.component';
import { ResourceTagEditComponent } from './settings/components/resource-tag-edit/tag-edit.component';

export const appRoutes: Routes = [
    { path: '', component: LandingComponent, canActivate: [AuthGuard] },
    { path: 'preview', component: PreviewFeaturedContentComponent, canActivate: [AuthGuard] },
    { path: 'auth/login', component: LoginComponent },
    { path: 'auth/logout', component: LogoutComponent },
    { path: 'auth/:provider', component: ExternalLoginComponent },

    { path: 'pipelines', component: PipelinesComponent, canActivate: [AuthGuard], data:{viewPermissions:['settings']} },
    { path: 'pipelines/create', component: PipelineCreateComponent, canActivate: [AuthGuard], data:{viewPermissions:['settings']} },
    { path: 'pipelines/:id', component: PipelineEditComponent, canActivate: [AuthGuard], data:{viewPermissions:['settings']} },

    { path: 'profiles/create', component: ProfileCreateComponent, canActivate: [AuthGuard], data:{viewPermissions:['settings']} },
    { path: 'profiles/:id', component: ProfileEditComponent, canActivate: [AuthGuard], data:{viewPermissions:['settings']} },

    { path: 'roles', component: RolesComponent, canActivate: [AuthGuard], data:{viewPermissions:['settings']} },
    { path: 'roles/create', component: RoleCreateComponent, canActivate: [AuthGuard], data:{viewPermissions:['settings']} },
    { path: 'roles/:id', component: RoleEditComponent, canActivate: [AuthGuard], data:{viewPermissions:['settings']} },

    { path: 'user/profiles/create/:parentId', component: UserProfileCreateComponent, canActivate: [AuthGuard] },
    { path: 'user/profiles/:id', component: UserProfileEditComponent, canActivate: [AuthGuard] },

    { path: 'workers/create', component: WorkerCreateComponent, canActivate: [AuthGuard], data:{viewPermissions:['settings']} },
    { path: 'workers/:id', component: WorkerEditComponent, canActivate: [AuthGuard], data:{viewPermissions:['settings']} },

    { path: 'featured', component: FeaturedComponent, canActivate: [AuthGuard], data:{viewPermissions:['content']} },
    { path: 'series', component: SeriesComponent, canActivate: [AuthGuard], data:{viewPermissions:['content']}, runGuardsAndResolvers: 'always' },
    { path: 'channels', component: ChannelsComponent, canActivate: [AuthGuard], data:{viewPermissions:['content']} },
    { path: 'channels/:id', component: ChannelEditComponent, canActivate: [AuthGuard], data:{viewPermissions:['content']} },

    { path: 'workflow/worklist/:searchterm', component: WorklistComponent, canActivate: [AuthGuard], data:{viewPermissions:['content']} },
    { path: 'workflow/worklist', component: WorklistComponent, canActivate: [AuthGuard], data:{viewPermissions:['content']} },

    { path: 'history/:type/:id', component: HistoryListComponent, canActivate: [AuthGuard], data:{viewPermissions:['history']} },
    { path: 'history/:type/:objectId/diff/:historyId', component: HistoryDiffComponent, canActivate: [AuthGuard], data:{viewPermissions:['history']} },
    { path: 'history/:type/:objectId/revert/:historyId/:latestHistoryId', component: HistoryRevertComponent, canActivate: [AuthGuard], data:{viewPermissions:['revert']} },


    { path: 'containers', component: ContainerRootComponent, canActivate: [AuthGuard], data:{viewPermissions:['content']} },
    { path: 'containers/create', component: ContainerCreateComponent, canActivate: [AuthGuard], data:{viewPermissions:['content']} },
    { path: 'containers/guid/:guid', component: ContainerGuidComponent, canActivate: [AuthGuard], data:{viewPermissions:['content']} },
    { path: 'containers/:id', component: ContainerEditComponent, canActivate: [AuthGuard], data:{viewPermissions:['content']} },
    { path: 'containers/:id/relate/:type', component: ContainerRelateComponent, canActivate: [AuthGuard], data:{viewPermissions:['content']} },
    { path: 'containers/:parentId/:childId/relatethrough/:type', component: ContainerRelateThroughComponent, canActivate: [AuthGuard], data:{viewPermissions:['content']} },
    { path: 'containers/:id/relatetoparents/:type', component: ContainerMultiParentRelateComponent, canActivate: [AuthGuard], data:{viewPermissions:['content']} },
    { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard], data:{viewPermissions:['content']} },

    { path: 'preview/featured', component: PreviewFeaturedContentComponent, canActivate: [AuthGuard], data:{viewPermissions:['content']} },
    { path: 'preview/series/:guid', component: PreviewSeriesDetailComponent, canActivate: [AuthGuard], data:{viewPermissions:['content']} },

    { path: 'containers/:containerId/resources', component: ResourceRootComponent, canActivate: [AuthGuard], data:{viewPermissions:['content']} },
    { path: 'containers/:containerId/resources/create', component: ResourceCreateComponent, canActivate: [AuthGuard], data:{viewPermissions:['content']} },
    { path: 'containers/:containerId/resources/upload', component: ResourceUploadComponent, canActivate: [AuthGuard], data:{viewPermissions:['upload']} },
    { path: 'containers/:containerId/resources/:resourceId', component: ResourceEditComponent, canActivate: [AuthGuard], data:{viewPermissions:['content']} },

    { path: 'testing/reports', component: TestingReportsComponent, canActivate: [AuthGuard], data:{viewPermissions:['run_tests']} },

    { path: 'metrics/dashboard', component: MetricsDashboardComponent, canActivate: [AuthGuard], data:{viewPermissions:['metrics']}  },
    { path: 'metrics/reports', component: MetricsReportsComponent, canActivate: [AuthGuard], data:{viewPermissions:['metrics']}  },
    { path: 'status', component: StatusComponent, canActivate: [AuthGuard], data:{viewPermissions:['metrics']}  },

    { path: 'applications', component: ApplicationsComponent, canActivate: [AuthGuard], data:{viewPermissions:['settings']} },
    { path: 'applications/create', component: ApplicationCreateComponent, canActivate: [AuthGuard], data:{viewPermissions:['settings']} },
    { path: 'applications/:id', component: ApplicationEditComponent, canActivate: [AuthGuard], data:{viewPermissions:['settings']} },

    { path: 'origins', component: SettingsOriginsComponent, canActivate: [AuthGuard], data:{viewPermissions:['settings']} },
    { path: 'origins/create', component: OriginCreateComponent, canActivate: [AuthGuard], data:{viewPermissions:['settings']} },
    { path: 'origins/:id', component: OriginEditComponent, canActivate: [AuthGuard], data:{viewPermissions:['settings']} },

    { path: 'providers', component: SettingsProvidersComponent, canActivate: [AuthGuard], data:{viewPermissions:['settings']} },
    { path: 'providers/create', component: ProviderCreateComponent, canActivate: [AuthGuard], data:{viewPermissions:['settings']} },
    { path: 'providers/:id', component: ProviderEditComponent, canActivate: [AuthGuard], data:{viewPermissions:['settings']} },

    { path: 'distributions/create/:appId', component: DistributionCreateComponent, canActivate: [AuthGuard], data:{viewPermissions:['settings']} },
    { path: 'distributions/:id/:appId', component: DistributionEditComponent, canActivate: [AuthGuard], data:{viewPermissions:['settings']} },
    { path: 'distributions/:id', component: DistributionEditComponent, canActivate: [AuthGuard], data:{viewPermissions:['settings']} },

    { path: 'versions/create/:distId', component: VersionCreateComponent, canActivate: [AuthGuard], data:{viewPermissions:['settings']} },
    { path: 'versions/:id/:distId', component: VersionEditComponent, canActivate: [AuthGuard], data:{viewPermissions:['settings']} },

    { path: 'configurations/create/:distId', component: ConfigurationCreateComponent, canActivate: [AuthGuard], data:{viewPermissions:['settings']} },
    { path: 'configurations/:id/:distId', component: ConfigurationEditComponent, canActivate: [AuthGuard], data:{viewPermissions:['settings']} },

    { path: 'settings/platforms', component: SettingsPlatformsComponent, canActivate: [AuthGuard], data:{viewPermissions:['settings']} },
    { path: 'settings/integrations', component: SettingsIntegrationsComponent, canActivate: [AuthGuard], data:{viewPermissions:['settings']} },
    { path: 'settings/policies', component: SettingsPoliciesComponent, canActivate: [AuthGuard], data:{viewPermissions:['settings']} },
    { path: 'settings/tests', component: SettingsTestsComponent, canActivate: [AuthGuard], data:{viewPermissions:['settings']} },

    { path: 'testsuite/create', component: TestSuiteCreateComponent, canActivate: [AuthGuard], data:{viewPermissions:['tests']} },
    { path: 'testsuite/:id', component: TestSuiteEditComponent, canActivate: [AuthGuard], data:{viewPermissions:['tests']} },

    { path: 'test/create/:parentId', component: TestCreateComponent, canActivate: [AuthGuard], data:{viewPermissions:['tests']} },
    { path: 'test/:id/:parentId', component: TestEditComponent, canActivate: [AuthGuard], data:{viewPermissions:['tests']} },
    { path: 'test/:id', component: TestEditComponent, canActivate: [AuthGuard], data:{viewPermissions:['tests']} },

    { path: 'tools', component: ToolsComponent, canActivate: [AuthGuard], data:{viewPermissions:['settings']} },

    { path: 'platform/create', component: PlatformCreateComponent, canActivate: [AuthGuard], data:{viewPermissions:['settings']} },
    { path: 'platform/:id', component: PlatformEditComponent, canActivate: [AuthGuard], data:{viewPermissions:['settings']} },

    { path: 'policy/create', component: PolicyCreateComponent, canActivate: [AuthGuard], data:{viewPermissions:['settings']} },
    { path: 'policy/:id', component: PolicyEditComponent, canActivate: [AuthGuard], data:{viewPermissions:['settings']} },

    { path: 'reports', component: SettingsReportsComponent, canActivate: [AuthGuard], data:{viewPermissions:['settings']} },
    { path: 'reports/create', component: ReportCreateComponent, canActivate: [AuthGuard], data:{viewPermissions:['settings']} },
    { path: 'reports/:id', component: ReportEditComponent, canActivate: [AuthGuard], data:{viewPermissions:['settings']} },

    { path: 'persons', component: PersonsComponent, canActivate: [AuthGuard], data:{viewPermissions:['persons']} },
    { path: 'persons/create', component: PersonCreateComponent, canActivate: [AuthGuard], data:{viewPermissions:['persons']} },
    { path: 'persons/:id', component: PersonEditComponent, canActivate: [AuthGuard], data:{viewPermissions:['persons']} },

    { path: 'cast/create/:parentId', component: CastCreateComponent, canActivate: [AuthGuard], data:{viewPermissions:['persons']} },
    { path: 'cast/:id', component: CastEditComponent, canActivate: [AuthGuard], data:{viewPermissions:['persons']} },

    { path: 'commerce/advertisement/create', component: AdvertisementCreateComponent, canActivate: [AuthGuard], data:{viewPermissions:['commerce']} },
    { path: 'commerce/advertisement/:id', component: AdvertisementEditComponent, canActivate: [AuthGuard], data:{viewPermissions:['commerce']}  },

    { path: 'commerce/campaign/create', component: CACampaignCreateComponent, canActivate: [AuthGuard], data:{viewPermissions:['commerce']}  },
    { path: 'commerce/campaign/:id', component: CACampaignEditComponent, canActivate: [AuthGuard], data:{viewPermissions:['commerce']}  },
    { path: 'commerce/campaign', component: CACampaignWorklistComponent, canActivate: [AuthGuard], data:{viewPermissions:['commerce']}  },

    { path: 'commerce/contentgroup/create', component: CAContentGroupCreateComponent, canActivate: [AuthGuard], data:{viewPermissions:['commerce']}  },
    { path: 'commerce/contentgroup/:id', component: CAContentGroupEditComponent, canActivate: [AuthGuard], data:{viewPermissions:['commerce']}  },
    { path: 'commerce/contentgroup', component: CAContextWorklistComponent, canActivate: [AuthGuard], data:{viewPermissions:['commerce']}  },

    { path: 'commerce/moment/:id', component: CAMomentEditComponent, canActivate: [AuthGuard], data:{viewPermissions:['commerce']}  },
    { path: 'commerce/creative/create', component: CreativeCreateComponent, canActivate: [AuthGuard], data:{viewPermissions:['commerce','content']} },
    { path: 'commerce/creative/:containerId', component: CreativeEditComponent, canActivate: [AuthGuard], data:{viewPermissions:['commerce','content']} },

    { path: 'server/create', component: ServerCreateComponent, canActivate: [AuthGuard], data:{viewPermissions:['settings']} },
    { path: 'server/:id', component: ServerEditComponent, canActivate: [AuthGuard], data:{viewPermissions:['settings']} },

    { path: 'catalog/create', component: CatalogCreateComponent, canActivate: [AuthGuard], data:{viewPermissions:['settings']} },
    { path: 'catalog/:id', component: CatalogEditComponent, canActivate: [AuthGuard], data:{viewPermissions:['settings']} },
   // { path: 'episode/create', component: EpisodeCreateComponent,   canActivate: [AuthGuard] },

    { path: 'document/create', component: DocumentCreateComponent, canActivate: [AuthGuard], data:{viewPermissions:['content']} },
    { path: 'document/:id', component: DocumentEditComponent, canActivate: [AuthGuard], data:{viewPermissions:['content']} },

    { path: 'mediaobject/create', component: MediaObjectCreateComponent, canActivate: [AuthGuard], data:{viewPermissions:['content']} },

    { path: 'episodes/:id/:parentId', component: EpisodeEditComponent, canActivate: [AuthGuard], data:{viewPermissions:['content']} },
    { path: 'episodes/:id', component: EpisodeEditComponent, canActivate: [AuthGuard], data:{viewPermissions:['content']} },

    { path: 'series/create', component: SeriesCreateComponent, canActivate: [AuthGuard], data:{viewPermissions:['content']} },
    { path: 'series/:id', component: SeriesEditComponent, canActivate: [AuthGuard], data:{viewPermissions:['content']} },
    { path: 'seasons/:id', component: SeasonEditComponent, canActivate: [AuthGuard], data:{viewPermissions:['content']} },
    { path: 'seasons/create/:parentId', component: SeasonCreateComponent, canActivate: [AuthGuard], data:{viewPermissions:['content']} },

    { path: 'collections/create', component: CollectionCreateComponent, canActivate: [AuthGuard], data:{viewPermissions:['content']} },
    { path: 'collections/:id', component: CollectionEditComponent, canActivate: [AuthGuard], data:{viewPermissions:['content']} },

    { path: 'season/:id/:parentId', component: SeasonEditComponent, canActivate: [AuthGuard], data:{viewPermissions:['content']} },
    { path: 'collection/:id/:parentId', component: CollectionEditComponent, canActivate: [AuthGuard], data:{viewPermissions:['content']} },
    { path: 'episode/:id/:parentId', component: EpisodeEditComponent, canActivate: [AuthGuard], data:{viewPermissions:['content']} },
    { path: 'media/:id/:parentId', component: MediaEditComponent, canActivate: [AuthGuard], data:{viewPermissions:['content']} },
    { path: 'media/:id', component: MediaEditComponent, canActivate: [AuthGuard], data:{viewPermissions:['content']} },

    { path: 'views', component: ViewsComponent, canActivate: [AuthGuard], data:{viewPermissions:['settings']} },
    { path: 'view/create/:parentId', component: ViewCreateComponent, canActivate: [AuthGuard], data:{viewPermissions:['settings']} },
    { path: 'view/:id', component: ViewEditComponent, canActivate: [AuthGuard], data:{viewPermissions:['settings']} },
    
    { path: 'movies', component: MoviesComponent, canActivate: [AuthGuard], runGuardsAndResolvers: 'always', data:{viewPermissions:['content']} },
    { path: 'movie/:id', component: MoviesEditComponent, canActivate: [AuthGuard], data:{viewPermissions:['content']} },

    { path: 'tags', component: TagsComponent, canActivate: [AuthGuard], data:{viewPermissions:['tag']} },
    { path: 'tags/assign', component: TagsAssignComponent, canActivate: [AuthGuard], data:{viewPermissions:['tag']} },
    { path: 'tags/create', component: TagCreateComponent, canActivate: [AuthGuard], data:{viewPermissions:['tag']} },
    { path: 'tags/:id', component: TagEditComponent, canActivate: [AuthGuard], data:{viewPermissions:['tag']} },

    { path: 'settings/schemas', component: SettingsSchemasComponent, canActivate: [AuthGuard], data:{viewPermissions:['schema']} },
    { path: 'schema/create', component: SchemaCreateComponent, canActivate: [AuthGuard], data:{viewPermissions:['schema']} },
    { path: 'schema/:id', component: SchemaEditComponent, canActivate: [AuthGuard], data:{viewPermissions:['schema']} },
    { path: 'schemas/:id/relate/:type', component: SchemaRelateComponent, canActivate: [AuthGuard], data:{viewPermissions:['schema']} },

    { path: 'entitytype/:id', component: EntitytypeEditComponent, canActivate: [AuthGuard], data:{viewPermissions:['entityType']} },
    { path: 'settings/entitytypes', component: EntityTypeComponent, canActivate: [AuthGuard], data:{viewPermissions:['entityType']} },

    { path: 'settings/notifications', component: NotificationsComponent, canActivate: [AuthGuard], data:{viewPermissions:['settings']} },
    { path: 'notification/create', component: NotificationCreateComponent, canActivate: [AuthGuard], data:{viewPermissions:['settings']} },
    { path: 'notification/:id', component: NotificationEditComponent, canActivate: [AuthGuard], data:{viewPermissions:['settings']} },

    { path: 'settings/workflows', component: WorkflowsComponent, canActivate: [AuthGuard], data:{viewPermissions:['workflow']} },
    { path: 'workflow/create', component: WorkflowCreateComponent, canActivate: [AuthGuard], data:{viewPermissions:['workflow']} },
    { path: 'workflow/:id', component: WorkflowEditComponent, canActivate: [AuthGuard], data:{viewPermissions:['workflow']} },

    { path: 'containers/:containerId/segments/workflow_state/create', component: WorkflowStateSegmentCreateComponent, canActivate: [AuthGuard], data:{viewPermissions:['workflow']} },
    { path: 'containers/:containerId/segments/:segmentType/create', component: SegmentCreateComponent, canActivate: [AuthGuard], data:{viewPermissions:['segment','workflow']} },
    { path: 'containers/:containerId/segments/:segmentId', component: SegmentEditComponent, canActivate: [AuthGuard], data:{viewPermissions:['segment','workflow']} },
    { path: 'containers/:containerId/segments/workflow_state/:segmentId', component: WorkflowSegmentEditComponent, canActivate: [AuthGuard], data:{viewPermissions:['workflow']} },

    { path: 'commerce/flight/create/:parentId', component: FlightCreateComponent, canActivate: [AuthGuard], data:{viewPermissions:['commerce']} },
    { path: 'commerce/flights/:id', component: FlightEditComponent, canActivate: [AuthGuard], data:{viewPermissions:['commerce']} },
    { path: 'commerce/flight/:id/:parentId', component: FlightEditComponent, canActivate: [AuthGuard], data:{viewPermissions:['commerce']} },

    { path: 'locks', component: LocksComponent, canActivate: [AuthGuard], data:{viewPermissions:['locks']} },

    { path: 'resourcetags', component: ResourceTagsComponent, canActivate: [AuthGuard], data:{viewPermissions:['resourcetag']}  },
    { path: 'resourcetag/create', component: ResourceTagCreateComponent, canActivate: [AuthGuard], data:{viewPermissions:['resourcetag']}  },
    { path: 'resourcetag/:id', component: ResourceTagEditComponent, canActivate: [AuthGuard], data:{viewPermissions:['resourcetag']}  },

];
