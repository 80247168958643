import { Component, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { MessageService } from 'primeng/api';
import { Tag } from '../../../content/models/tag';
import { TagFormComponent } from '../../forms/tag-form/tag-form.component';
import { TagService } from '../../../content/services/tag/tag.service';
import { Router } from '@angular/router';



@Component({
  selector: 'tag-create',
  providers: [
    TagService
],
  templateUrl: './tag-create.component.html',
  styleUrls: []
})
export class TagCreateComponent implements OnInit {
  @ViewChild('form') form: TagFormComponent;
  public tag: Tag;
  public isLoading:boolean = false

  constructor(
    private location: Location,
    private router: Router,
    private tagService: TagService,
    private msgService: MessageService,
   ) { }

  ngOnInit() {
    this.tag = new Tag();

  }

  onSubmit(tag: Tag){
    this.isLoading = true
    this.tagService.save(tag).subscribe(
      res => this.router.navigate(['/tags', res.id], { replaceUrl: true }),
      err => {
        console.log(err)
        this.isLoading = false
      },
      () => this.isLoading = false
    );
  }

  onCancel(e) {
   this.location.back()
  }

  onFail(e){
    this.msgService.add({ severity: 'error', summary: 'Error', detail: 'Please enter the tag value.' });
  }

}
