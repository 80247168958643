<div *ngxPermissionsOnly="['view.show.schema']">
    <div class="card card-w-title top-level-card">
        <h1>Schemas</h1>
            <p-toolbar>
                <div class="ui-g">
                    <div class="ui-g-4">
                        <input pInputText
                            type="text" 
                            placeholder="Filter" 
                            [(ngModel)]="filterTerm" 
                            (ngModelChange)="changedFilter($event)"
                            style="text-align:center;height:100%;width:100%;"
                            data-element-id="app-settings-components-schemas-component-text"/>
                    </div>
                    <div class="ui-g-4">
                        <p-dropdown [options]="statusList" 
                            (onChange)="onChangedStatus($event)" 
                            placeholder="No Status Filter" 
                            optionLabel="value" 
                            [showClear]="true" 
                            [ngModel]="selectedStatus" 
                            [style]="{'height':'100%','width':'100%'}"
                            data-element-id="app-settings-components-schemas-component-status-filter">
                        </p-dropdown>
                    </div>
                    <div class="ui-g-4">
                        <button pButton type="button" label="Create" [routerLink]="['/schema', 'create']" icon="pi pi-plus" class="p-button-success" style="height:100%;float:right;" data-element-id="app-settings-components-schemas-component-create"></button>
                    </div>
                    <spinner *ngIf="isLoading"></spinner>
                </div>
            </p-toolbar>
        <p-table #dt
                [columns]="columns"
                [value]="schemas"  
                [autoLayout]="true" 
                [globalFilterFields]="['label', 'guid', 'type', 'status']" 
                [rows]="this.rows" 
                [paginator]="true" 
                paginatorPosition="both" 
                dataKey="guid" 
                [responsive]="true"
                styleClass="p-datatable-gridlines"
                expandableRows="false">
            <ng-template pTemplate="header" let-columns>
                <tr>
                    <th *ngFor="let col of columns" [ngStyle]="col.style">
                        {{col.header}}
                    </th>
                    <th></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-schema>
                <tr>
                    <td *ngFor="let col of columns">
                        <div [ngStyle]="col.style" [innerHTML]="getFieldData(schema, col)"></div>
                    </td>
                    <td>
                        <div *ngxPermissionsOnly="['view.show.settings']">
                            <button pButton 
                                type="button" 
                                label="Edit" 
                                class="p-button-success" 
                                [routerLink]="['/schema', schema.id]" 
                                icon="pi pi-pencil" 
                                style="width:6rem;font-size:13px;"
                                data-element-id="app-settings-components-schemas-component-edit">
                            </button>
                        </div>
                    </td>
                </tr>    
            </ng-template>
        </p-table>
    </div>
</div>

