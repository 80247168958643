import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SelectItem } from 'primeng/api';
import { Container } from '../../models';
import { DragulaService } from 'ng2-dragula';
import { ContainerService } from '../../services/container/container.service';
import { AppContext } from '../../../app.context';
import { Subscription } from 'rxjs';

@Component({
    selector: 'container-paginated-datarow',
    viewProviders: [
        DragulaService
    ],
    providers: [
        ContainerService,
    ],
    templateUrl: 'container-paginated-datarow.component.html',
    styleUrls: ['container-paginated-datarow.component.css']
})
export class ContainerPaginatedDatarowComponent implements OnInit, OnDestroy {
    @Input() parentContainer: Container;
    @Input() title: string;
    @Input() type: string;
    @Input() itemsPerPage: number = 10;
    @Input() rows: number;
    @Input() filterBy: string;
    @Input() sortOptions: Array<any>;
    @Input() ordering: string;
    @Input() status: string;
    @Input() isLoading: boolean = true;
    @Input() typeExclude:Array<string>;
    @Output() reorder: EventEmitter<any> = new EventEmitter();
    @Input() reloadNotification:Boolean;
    @Output() countUpdatedEvent: EventEmitter<any> = new EventEmitter();
    @Input() badgeVisible: boolean = true;

    private allContainers: Array<Container> = [];
    private shownContainers: Array<Container> = [];
    private sortField: string;
    private sortOrder: number;
    private activeFilter: string;
    private filterByFields: Array<any>;
    private name: string = '';
    private page: number = 1;
    private lastPageReached: boolean = false;
    public loadingNextPage: boolean = false;
    public statusList: Array<SelectItem> = [];
    private child_row_name = '';
    private dragulaSub: Subscription;
    private error: any;
    public collapsed = false;
    public containerCount: number;


    public orderingOptions = [
        { label: 'Curated Order', value: "to_container__order,-created_date" },
        { label: 'Curated Order Desc', value: "-to_container__order,created_date" },
        { label: 'Published Date', value: "published_date,id" },
        { label: 'Published Date Desc', value: "-published_date,-id" },
        { label: 'Created Date', value: "created_date,id" },
        { label: 'Created Date Desc', value: "-created_date,-id" },
        { label: 'Reference Date', value: "reference_date,id" },
        { label: 'Reference Date Desc', value: "-reference_date,-id" },
    ]


    public selectedOrderingOption: any;
    public selectedStatusOption: any;


    constructor(
        private dragulaService: DragulaService,
        private appContext: AppContext,
        private route: ActivatedRoute,
        private containerService: ContainerService
    ) {}

    ngOnChanges(changes: SimpleChanges){
      if (typeof changes.reloadNotification?.currentValue !== 'undefined') {
        this.allContainers = [];
        this.shownContainers = [];
        this.ngOnInit()
      }
    }

    ngOnInit() {
        this.child_row_name = `${this.parentContainer.guid}:${this.type}`;

        this.statusList = ContainerService.statusList.sort().map(s => ({ label: s, value: s }));
        this.statusList.unshift({ label: 'Any Status', value: '' });

        if (!this.appContext.componentState['sort_order'][this.child_row_name]) {
            this.setDefaultParams();
            this.setSortParams();
        }
        this.getSortParams();

        let filters = this.filterBy.split(', ');
        this.filterByFields = [];
        filters.forEach(element => {
            this.filterByFields.push(element.split('.'));
        });

        this.name = Math.random()
            .toString(36)
            .substring(7);

        this.dragulaService.createGroup(this.name, {});

        this.dragulaSub = this.dragulaService.dropModel(this.name)
            .subscribe((e) => {
                console.log('drop', e);
                this.onDrop(e);
        });

        let ordering = '';
        if (this.ordering) {
            ordering = this.ordering;
        }

        this.loadPage();
    }

    ngOnDestroy() {
        this.dragulaSub.unsubscribe();
    }

    private getSortParams() {
        this.ordering = this.appContext.componentState['sort_order'][this.child_row_name]['ordering'];
        this.selectedOrderingOption = this.ordering;

        this.status = this.appContext.componentState['sort_order'][this.child_row_name]['status'];
        this.selectedStatusOption = this.status;
    }

    private loadPage() {
        // Do not trigger small spinner if the state is (re)loading entirely
        this.loadingNextPage = !this.isLoading;
        this.containerService
            .getRelatedByTypeAndGuidPaginated(this.type, this.parentContainer.guid, this.page, this.itemsPerPage, this.ordering, '', this.status, this.typeExclude)
            .subscribe(
                res => {
                    // Prevent updates if the last page has been reached
                    if (res.length < this.itemsPerPage) this.lastPageReached = true;

                    res.forEach(element => {
                        element['show'] = true;
                        this.shownContainers.push(element);
                        this.allContainers.push(element);
                    });
                    this.filter();
                    this.getItemCount();
                },
                err => {
                    this.loadingNextPage = false;
                    this.isLoading = false;
                    this.error = err;
                },
                () => {
                    this.loadingNextPage = false;
                    this.isLoading = false;
                }
            );
    }

    private loadNextPage() {
        if (!this.loadingNextPage && !this.lastPageReached) {
            this.page = this.page + 1;
            this.loadPage();
        }
    }

    private resetState() {
        this.page = 1;
        this.allContainers = [];
        this.shownContainers = [];
        this.lastPageReached = false;
        this.isLoading = true;
    }

    public onOrderingChange() {
        this.ordering = this.selectedOrderingOption;
        this.resetState();
        this.loadPage();
        this.setSortParams();
    }

    public onStatusChange() {
        this.status = this.selectedStatusOption;
        this.resetState();
        this.loadPage();
        this.setSortParams();
    }

    private onScroll(event) {
        // scrollLeft is a decimal value for some zoom levels. So, it is rounded up
        if (event.target.offsetWidth + Math.ceil(event.target.scrollLeft) >= event.target.scrollWidth){
            this.loadNextPage();
        }
    }

    private onDrop(e) {

        if (!this.selectedOrderingOption.includes("to_container__order")) {
            alert('Drag and drop ordering is only available in Curated ordering');
            this.onOrderingChange();
            return;
        }

        if (this.parentContainer) {
            let el = e['el'];
            let droppedItem = e['item'];
            let oldIndex = this.allContainers.indexOf(droppedItem);
            let newIndex = this.getElementIndex(el);
            // depending on the direction of dragging,
            // previous item can be retrieved by either getting using the new index
            // of dragged item of the old container array, or by using new index - 1
            let prevItemIndex = oldIndex > newIndex ? newIndex - 1 : newIndex;
            let droppedAfterItem = prevItemIndex >= 0 ? this.allContainers[prevItemIndex] : null;

            this.reorder.emit({
                target: this,
                parent: this.parentContainer,
                from: droppedItem,
                to: droppedAfterItem
            });
        }
    }

    private getElementIndex(el) {
        // previousElementSibling is only supported in IE10+
        for (var i = 0; el = el.previousElementSibling; i++);
        return i;
    }

    onFilter(value) {
        this.activeFilter = value;
        this.filter();
    }

    filter() {
        this.shownContainers.forEach(container => {
            if (this.activeFilter && this.activeFilter !== '') {
                container['show'] = false;
                this.filterByFields.forEach(filter => {
                    if (!container['show']) {
                        var result = container;
                        filter.forEach(element => {
                            result = result[element];
                        });
                        if (String(result).toLowerCase().includes(this.activeFilter.toLowerCase())) {
                            container['show'] = true;
                        }
                    }
                });
            } else {
                container['show'] = true;
            }
        })
    }

    onSortChange(event) {
        let value = event.value;

        if (value.indexOf('!') === 0) {
            this.sortOrder = -1;
            this.sortField = value.substring(1, value.length);
        } else {
            this.sortOrder = 1;
            this.sortField = value;
        }

        this.sort();
    }

    setSortParams() {
        this.appContext.componentState['sort_order'][this.child_row_name] = {
            ordering: this.ordering,
            status: this.status,
        };
     }

    setDefaultParams() {
        if (!this.ordering) {
            this.ordering = '-reference_date,-id';
        }
        this.status = '';
    }

    sort() {
        if (!this.sortField || this.sortField === '') {
            this.shownContainers = [];
            this.allContainers.forEach(element => {
                this.shownContainers.push(element);
            });

            this.filter();
            return;
        }

        this.shownContainers.sort((a, b) => {
            var value1 = this.resolveObjectField(a, this.sortField);
            var value2 = this.resolveObjectField(b, this.sortField);

            var result = null;
            if (value1 == null && value2 != null)
                result = -1;
            else if (value1 != null && value2 == null)
                result = 1;
            else if (value1 == null && value2 == null)
                result = 0;
            else if (typeof value1 === 'string' && typeof value2 === 'string')
                result = value1.localeCompare(value2);
            else
                result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
            return (this.sortOrder * result);
        });
    }


    resolveObjectField(object: Object, value: string) {
        var tokens = value.split('.');
        var result = object;
        tokens.forEach(element => {
            result = result[element];
        });
        return result;
    }

    containerRemoved(e) {
        let i = this.allContainers.indexOf(e.container);
        this.allContainers.splice(i, 1);
        
        this.containerCount = (this.containerCount > 0) ? this.containerCount - 1 : 0;
        this.countUpdatedEvent.emit(this.containerCount);

        this.shownContainers = [];
        this.allContainers.forEach(element => {
            this.shownContainers.push(element);
        });

        this.filter();
        this.sort();
    }

    getItemCount() {
        this.containerService
            .getTotalItemCount(
                this.parentContainer.guid,
                this.type,
                this.status || ContainerService.statusList
            )
            .subscribe(
                res => {
                    this.containerCount = res.count;
                    this.countUpdatedEvent.emit(this.containerCount);
                },
                err => { 
                    this.error = err;
                }
            );
    }
}
