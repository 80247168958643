<div *ngxPermissionsOnly="['view.show.entityType']" class="card card-w-title top-level-card" [ngClass]="{'hide': selectedOrigin!=='system'}">
  <h1>Entity Types</h1>
  <div class="ui-g">
    <p-messages styleClass="w-100"></p-messages>
  </div>
    <p-table #dt [value]="eTypes"
            [rows]=20
            [paginator]="true"
            [loading]="isLoading"
            paginatorPosition="bottom"
            selectionMode="single"
            styleClass="p-datatable-gridlines"
            [responsive]="true"
            [globalFilterFields]="['name', 'schema']"
            >
        <ng-template pTemplate="caption">
          <div class="ui-g">
            <div class="ui-g-4">
              <input type="text" id="type" name="type" pInputText placeholder="Filter by entitytype"
                    (keyup)="dt.filterGlobal($event.target.value.toLowerCase(), 'contains')"
                    style="width:100%"
              />
            </div>
            <div class="ui-g-8">
              <button pButton type="button" label="Create" [routerLink]="['/entitytype', 'create']" icon="pi pi-plus" class="p-button-success" style="height:100%;float:right;"></button>
            </div>
          </div>
        </ng-template>
        <ng-template pTemplate="header">
            <tr>
              <th>Name</th>
              <th style="width:5rem"></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-etype>
            <tr>
              <td>{{etype.name}}</td>
              <td>
                <span>
                  <button pButton type="button" class="p-button-primary" label="Edit" [routerLink]="['/entitytype', etype.id]"></button>
                </span>
              </td>
            </tr>
        </ng-template>
    </p-table>
</div>
