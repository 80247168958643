import { environment } from '../environments/environment';
import { Injectable, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from './auth/auth.service';
import { Observable, BehaviorSubject, ReplaySubject } from 'rxjs';
import { Router } from '@angular/router';
import { ProfileService } from '../app/content/services/profile/profile.service';
import { Profile } from './content/models';

@Injectable()
export class AppContext {
    public activeOrigin: string = '';
    //TODO: Should load/save component states between sessions on local store
    public componentState = {}
    public activeOriginObject = {}
    public activeOriginState$ = new BehaviorSubject(this.activeOrigin);
    public originContainersLoaded$ = new ReplaySubject();
    public originContainers = [];
    private isLoadingOrigin = false;
    //TODO: Make struc of model to store metadata logo, etc..
    public authorizedOrigins: any[] = [];

    public originObjects = {};

    public profile: Profile;

    private baseUrl = `${environment.PROXY_API_BASE_URL}/content`;

    constructor(
        private http: HttpClient,
        private authService: AuthService,
        private router: Router,
        private profileService: ProfileService,) {
        this.activeOrigin = this.getActiveOrigin();
        this.activeOriginObject = this.getActiveOriginObject();

        this.componentState['search_view'] = {};
        this.componentState['policy_view'] = {};
        this.componentState['provider_view'] = {};
        this.componentState['sort_order'] = {};
        this.componentState['pipeline_list'] = {};
        this.componentState['report_view'] = {};
        this.componentState['history_view'] = {};
        this.componentState['schemas_view'] = {};
        this.componentState['workflow_view'] = {};
        this.componentState['persons_view'] = {};
        this.componentState['tests_view'] = {};
        
        

        this.authService.userState.subscribe(
            res => {
                if (res.id) {
                    this.isLoadingOrigin = true;
                    this.parseAuthorizedOrigins(res);
                    this.filterByTypeName()
                        .subscribe(
                            res => {
                                this.originContainers = res;
                                console.log(res);
                                this.originContainersLoaded$.next(this.originContainers);
                                // Reset this.originObjects?
                                this.originContainers.forEach((element, index) => {
                                    this.originObjects[element.data['origin_id']] = element.data;
                                })
                                console.log("origins loaded");
                                console.log(this.originObjects);

                                let oldOrigin = this.activeOrigin;
                                this.setActiveOrigin(this.activeOrigin);
                                if (oldOrigin != this.activeOrigin) {
                                    this.reloadOnOriginChange();
                                }
                            },
                            err => {
                                console.log(err);
                            },
                            () => this.isLoadingOrigin = false
                        );
                    if (res.profile_id) {
                        this.profileService
                            .get(res.profile_id)
                            .subscribe(
                                res => {
                                    this.setProfile(res);
                                },
                                err => {
                                    console.log(err);
                                },
                            );
                    }
                }
            },
            err => { console.log(err) },
            () => { }
        )
    }

    private emptyOrigin(): Object {
        return {
            'origin_id': '',
            'logo_url': '',
        }
    }

    private emptyProfile(): Profile {
        return {
            'id': -1,
            'guid':'',
            'data':'',
            'created_date':'',
            'last_access_date':'',
            'updated_date': '',
            'user':''
        }
    }

    private parseAuthorizedOrigins(user: Object) {
        let permissions = user['permissions'] || {};
        this.authorizedOrigins = Object.keys(permissions).sort();
        console.log(this.authorizedOrigins);
    }

    filterByTypeName(): Observable<any> {
        let headers = new HttpHeaders()
            .append('Accept', 'application/json')
            .append('Authorization', this.authService.getAuthHeader());

        return this.http
            .get(
                `${this.baseUrl}/containers/search/?type=origin&nest=full&depth=-1&origin=system`,
                { headers: headers }
            );
    }

    prepareViewDataOnOriginSwitch()
    {
        if (this.componentState['search_view']) {
            this.componentState['search_view']['offset'] = 0;
        }

    }

    reloadOnOriginChange() {
        this.prepareViewDataOnOriginSwitch();

        let urlComponents = this.router.url.split('/');
        if (urlComponents.length <= 2) {
            this.router.navigate([this.router.url]);
        }
        else {
            this.router.navigate([ '/' + urlComponents[1]]).then(data => {
                console.log('Route exists, redirection is done');
              })
              .catch(e => {
                this.router.navigate(['/']);
              });;
        }
    }

    onSelectNewOriginClick(event: Event, origin: string) {
        event.preventDefault();
        if (this.activeOrigin != origin) {
            this.setActiveOrigin(origin);
            this.reloadOnOriginChange();
            this.activeOriginState$.next(origin);
        }
    }

    getActiveOrigin() {
        const activeOrigin = localStorage.getItem('active_origin');
        return activeOrigin;
    }

    getActiveOriginObject() {
        let activeOriginString = localStorage.getItem('active_origin_object')
        let activeOriginObject = this.emptyOrigin();
        if (activeOriginString) {
            activeOriginObject = JSON.parse(activeOriginString);
        }
        return activeOriginObject;
    }

    setActiveOrigin(activeOrigin) {
        if (!activeOrigin || !this.authorizedOrigins.includes(activeOrigin)) {
            activeOrigin = this.authorizedOrigins[0] || '';
        }
        this.activeOrigin = activeOrigin;
        localStorage.setItem('active_origin', activeOrigin);
        this.activeOriginObject = this.originObjects[activeOrigin];
        if (this.activeOriginObject) {
            localStorage.setItem('active_origin_object', JSON.stringify(this.activeOriginObject));
        }
        else {
            this.activeOriginObject = this.emptyOrigin();
            localStorage.setItem('active_origin_object', '');
        }
        console.log('origin:', this.activeOrigin);
        this.authService.setPermissionsOrigin(this.activeOrigin);
    }

    getOriginLabel(origin) {
        let originObj = this.originObjects[origin];
        if (originObj && originObj['label']) {
            return originObj['label'];
        }
        return origin;
    }

    getProfile() {
        let profileString = localStorage.getItem('profile');
        let profileObject = this.emptyProfile();
        if (profileString) {
            profileObject = JSON.parse(profileString);
        }
        return profileObject
    }

    setProfile(profile) {
        localStorage.setItem('profile', JSON.stringify(profile));
    }

    getViewRestrictions(view)
    {
        let originViewRestictions = this.authService.getViewRestrictionsForOrigin(this.activeOrigin)

        if (view in originViewRestictions)
        {
            return originViewRestictions[view]
        }
        return {}
    }

}
